<template>
  <div class="list">
    <div class="container_wrap">
      <Header />

      <div class="list_container">
        <el-row :gutter="15">
          <el-col :span="7">
            <ul class="side_muen marb_15">
              <li>
                <a href="/memberSub?id=1&name=集团高管">集团高管</a>
              </li>
              <!-- <li>
                <a href="/memberSub?id=2&name=高管助理">高管助理</a>
              </li> -->
            </ul>

            <!-- <div class="marb_15">
              <NewsCompany />
            </div>

            <div class="marb_15">
              <NewsPic />
            </div> -->

          </el-col>
          <el-col :span="17">
            <div class="list_hd">
              <span class="list_hd_posi"></span>
              <span class="list_hd_page"><a href="/">首页</a> > </span>
              <span class="list_hd_page"
                ><a href="">领导成员</a> >
              </span>
              <span class="list_hd_page">列表</span>
            </div>

            <div class="special_list">
              <div class="member_hd">集团高管</div>
              <div class="member_list">
                <div class="member_wrap clearfloat" v-for="(item,index) in listJtgg" :key="index">
                  <div class="fl member_fl">
                    <img :src="item.img" alt="">
                  </div>
                  <div class="member_fl_inner">
                    <div class="member_tit">
                      {{item.title}}
                    </div>
                    <div class="member_inner" v-html="item.content">
                      <!-- {{item.content}} -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="member_hd">高管助理</div> -->
              <div class="member_list">
                <div class="member_wrap clearfloat" v-for="(item,index) in listJtggSub" :key="index">
                  <div class="fl member_fl">
                    <img :src="item.img" alt="">
                  </div>
                  <div class="member_fl_inner">
                    <div class="member_tit">
                      {{item.title}}
                    </div>
                    <div class="member_inner" v-html="item.content">
                      <!-- {{item.content}} -->
                    </div>
                  </div>
                </div>
              </div>
              
            </div>

          </el-col>
        </el-row>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
// import NewsPic from "@/components/newsPic.vue";
// import NewsCompany from "@/components/newsCompany.vue";

export default {
  name: "member",
  components: {
    Header,
    Footer,
    // NewsPic,
    // NewsCompany
  },
  data() {
    return {
      name1: "专题专栏",
      name2: "",
      total: 0,
      pageSize: 20,
      currentPage: 1,
      list: [],
      newsJtdt: [],
      picList: [],
      listJtgg: [],
      listJtggSub: []
    };
  },
  mounted() {
    var _this = this;
    _this.getZtzlNews();
    _this.getListJtgg()
    // _this.getListJtggSub()
    
  },
  methods: {
    async getZtzlNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: 20210202});
      _this.picList = data.data;
    },
    async getListJtgg(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getAllNewsList', {group_id: 162});
      _this.listJtgg = data.data;
    },
    async getListJtggSub(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getAllNewsList', {group_id: 164});
      _this.listJtggSub = data.data;
    },
  },
};
</script>

<style scoped>
</style>