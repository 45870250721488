<template>
  <div class="newsPic">
    <div class="sec_wrap" style="margin-top: 12px;">
      <div class="sec_tit">
        <span><a href="/list?id=7&navName=新闻中心&groupName=集团动态">图片新闻</a></span>
      </div>
      <div class="list_l_xwdt">
        <div class="list_l_xwdt_list clearfloat" v-for="(item, index) in list"
          :key="index">
          <div class="fl list_l_xwdt_fl" v-if="item.img && item.img !== ''">
            <a class="list_l_xwdt_list_img" target="_blank" :href="`/detail?id=${item.id}&navName=新闻中心&groupName=集团动态`"
              ><img :src="item.img" alt=""
            /></a>
          </div>
          <div class="list_l_xwdt_inner" :class="{'list_l_xwdt_inner_txt' : !item.img || item.img == ''}">
            <p>
              <a target="_blank" :href="`/detail?id=${item.id}&navName=新闻中心&groupName=集团动态`"> {{ item.title }} </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsPic',
  data(){
    return {
      list: []
    }
  },
  mounted(){
     var _this = this

    _this.getJtdtNews();
  },
  methods: {
    async getJtdtNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsListImg",
        { page: 1, group_id: 7,  }
      );
      var _data = [];
      data.data.list.forEach(row => {
        if(row.img){
          _data.push(row)
        }
      });
      _this.list = _data.slice(0, 5);
    },

  }
}
</script>