<template>
  <div class="party">
    <div class="list">
      <div class="container_wrap">
        <Header />

        <div class="list_container">
          <el-row :gutter="10">
            <el-col :span="7">
              <ul class="side_muen marb_15">
                <li>
                  <a href="/list?id=171&navName=党建之窗&groupName=党建工作">党建工作</a>
                </li>
                <li>
                  <a href="/discipline">纪检监察</a>
                </li>
              </ul>
              <div class="marb_15">
                <NewsCompany />
              </div>
              <div class="marb_15">
                <Rank />
              </div>
              
            </el-col>

            <el-col :span="10">
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=171&navName=党建之窗&groupName=党建工作">党建工作</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=171&navName=党建之窗&groupName=党建工作">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listDjgz"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=党建之窗&groupName=党建工作`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/discipline">纪检监察</a></span>
                  <div class="fr company_more">
                    <a href="/discipline">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listJjjc"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=纪检监察&groupName=工作动态`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>

            </el-col>

            <el-col :span="7">
              <div class="marb_15">
                <Notic />
              </div>
              <div class="marb_15">
                <NewsPic />
              </div>
            </el-col>
          </el-row>

        </div>

        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Rank from "@/components/rank.vue";
import Notic from "@/components/notic.vue";
import NewsPic from "@/components/newsPic.vue";
import NewsCompany from "@/components/newsCompany.vue";

export default {
  name: 'party',
  components: {
    Header,
    Footer,
    Rank,
    Notic,
    NewsPic,
    NewsCompany
  },
  data(){
    return {
      listDjgz: [],
      listJjjc: [],
    }
  },
  mounted(){
    var _this = this
    _this.getDjgz()
    _this.getJjjc()
  },
  methods: {
    async getDjgz(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {page: 1, group_id: 171});
      _this.listDjgz = data.data.list.slice(0,5);
    },
    async getJjjc(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {page: 1, group_id: 172});
      _this.listJjjc = data.data.list.slice(0,5);
    },    
  }
}
</script>