<template>
  <div class="list">
    <div class="container_wrap">
      <Header />

      <div class="list_container">
        <el-row :gutter="15">
          <el-col :span="7">
            <div class="marb_15">
              <NewsCompany />
            </div>

            <div class="marb_15">
              <NewsPic />
            </div>
          </el-col>
          <el-col :span="17">
            <div class="list_hd">
              <span class="list_hd_posi"></span>
              <span class="list_hd_page"><a href="/">首页</a> > </span>
              <span class="list_hd_page"
                ><a href="/special">专题专栏</a> >
              </span>
              <span class="list_hd_page"
                ><a href="">党史学习教育</a> >
              </span>
              <span class="list_hd_page">列表</span>
            </div>

            <div class="special_list">
              <el-row :gutter="30">
                <el-col class="party_list_inner" :span="12" v-for="(item,index) in picList" :key="index">
                  <a v-if="!item.is_link || item.is_link === 0" :href="`/list?id=${item.id}&navName=党史学习教育&groupName=${item.name}`" :title="item.name">
                    <img :src="item.img">
                  </a>
                  <a v-if="item.is_link === 1" target="_blank" :href="item.link_url" :title="item.name">
                    <img :src="item.img">
                  </a>
                </el-col>
              </el-row>
            </div>

          </el-col>
        </el-row>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import NewsPic from "@/components/newsPic.vue";
import NewsCompany from "@/components/newsCompany.vue";

export default {
  name: "special",
  components: {
    Header,
    Footer,
    NewsPic,
    NewsCompany
  },
  data() {
    return {
      name1: "专题专栏",
      name2: "",
      total: 0,
      pageSize: 20,
      currentPage: 1,
      list: [],
      newsJtdt: [],
      picList: [
        {
          id: 194,
          name: '中央精神',
          img: require("../assets/img/zz_ds_img01.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 195,
          name: '甘肃部署',
          img: require("../assets/img/zz_ds_img02.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 196,
          name: '党史知识',
          img: require("../assets/img/zz_ds_img03.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 197,
          name: '党史故事',
          img: require("../assets/img/zz_ds_img04.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 198,
          name: '集团活动',
          img: require("../assets/img/zz_ds_img05.png"),
          is_link: 0,
          link_url: ''
        },
        {
          id: 199,
          name: '信息动态',
          img: require("../assets/img/zz_ds_img06.png"),
          is_link: 0,
          link_url: ''
        }
      ]
    };
  },
  mounted() {
    // var _this = this;
    // _this.getZtzlNews();
  },
  methods: {
    async getZtzlNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: 20210202});
      _this.picList = data.data;
    },
  },
};
</script>

<style scoped>
.party_list_inner {
  text-align: center;
  margin: 40px 0;
}

.party_list_inner img {
  width: 306px;
  height: 92px;
}
</style>