<template>
  <div class="list">
    <div class="container_wrap">
      <Header />

      <div class="list_container">
        <el-row :gutter="15">
          <el-col :span="7">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span>集团动态</span>
              </div>
              <ul class="list_l_jtdt">
                <li
                  class="single-line"
                  v-for="(item, index) in newsJtdt"
                  :key="index"
                >
                  <a
                    v-if="!item.is_link || item.is_link === 0"
                    target="_blank"
                    :href="
                      `/detail?id=${item.id}&navName=${name1}&groupName=${name2}`
                    "
                    >{{ item.title }}</a
                  >
                  <a
                    v-if="item.is_link === 1"
                    target="_blank"
                    :href="item.link_url"
                    >{{ item.title }}</a
                  >
                </li>
              </ul>
            </div>

            <div class="marb_15">
              <NewsPic />
            </div>

            <!-- <div class="sec_wrap" style="margin-top: 12px;">
              <div class="sec_tit">
                <span>图片新闻</span>
              </div>

              <div class="list_l_xwdt">
                <div class="list_l_xwdt_list clearfloat" v-for="(item, index) in newsJtdt"
                  :key="index">
                  <div class="fl list_l_xwdt_fl">
                    <a class="list_l_xwdt_list_img" :href="`/detail?id=${item.id}&navName=${name1}&groupName=${name2}`"
                      ><img :src="item.img" alt=""
                    /></a>
                  </div>
                  <div class="list_l_xwdt_inner">
                    <p>
                      <a :href="`/detail?id=${item.id}&navName=${name1}&groupName=${name2}`"> {{ item.title }} </a>
                    </p>
                  </div>
                </div>

              </div>
            </div> -->
          </el-col>
          <el-col :span="17">
            <div class="list_hd">
              <span class="list_hd_posi"></span>
              <span class="list_hd_page"><a href="/">首页</a> > </span>
              <span class="list_hd_page" v-show="navNameShow"
                ><a :href="navNameUrl">{{ name1 }}</a> >
              </span>
              <span class="list_hd_page"
                ><a href="">{{ name2 }}</a> >
              </span>
              <span class="list_hd_page">正文</span>
            </div>

            <div class="detail_wrap">
              <div class="detail_hd">
                <div class="detail_hd_title" v-html="cont.title"></div>
                <div v-if="cont.sub_title && cont.sub_title !== '' && cont.sub_title !== 'null' && cont.sub_title !== 'undefined'" class="detail_hd_subtitle" v-html="cont.sub_title"></div>
                <div class="detail_hd_inner">
                  <span
                    >浏览次数：<label>{{ cont.view_number }}</label></span
                  >
                  <span
                    >发布时间：<label>{{
                      dayjs(cont.create_time).format("YYYY-MM-DD HH:mm:ss")
                    }}</label></span
                  >
                </div>
              </div>
              <div class="detail_cont" v-html="cont.content"></div>
            </div>

            <!-- <div class="detail_ewm">
              <div class="detail_ewm_img">
                <qriously :value="appSrc" :size="size"/>
              </div>
              <p>扫码手机查看新闻</p>
            </div> -->

            <!-- <div class="pagepic">
              <img src="http://www.ghatg.com/gsghl/template/images/B_print.gif" @click="doPrint()">
              <img src="http://www.ghatg.com/gsghl/template/images/B_close.gif" @click="windowClose()">
              <img src="http://www.ghatg.com/gsghl/template/images/B_top.gif" @click="toTop()">
            </div> -->
          </el-col>
        </el-row>
      </div>

      <div class="ewmFloat">
        <div
          class="ewmFloatWx trans"
          @mouseenter="handleEnter"
          @mouseleave="handleLeave"
        >
          <div class="ewmFloatWxBox" v-show="isEwm">
            <div class="ewmFloatWxWrap">
              <qriously :value="appSrc" :size="size" />
              <p>手机扫码查看新闻</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
var wx = require("weixin-js-sdk");
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import NewsPic from "@/components/newsPic.vue";

var ua = window.navigator.userAgent.toLowerCase();
function isWeiXin() {
  return ua.match(/micromessenger/i) == "micromessenger";
}
function isComWeiXin() {
  return (
    ua.match(/micromessenger/i) == "micromessenger" &&
    ua.match(/wxwork/i) == "wxwork"
  );
}

// import VueQr from "vue-qr";

export default {
  name: "detail",
  components: {
    Header,
    Footer,
    NewsPic
    // VueQr
  },
  data() {
    return {
      isEwm: false,
      appSrc: `https://m.ghatg.com/detail?id=${this.$route.query.id}`,
      logoSrc: require("@/assets/img/logo_ewm.png"),
      size: 150,
      name1: "",
      name2: "",
      cont: {},
      newsJtdt: [],
      navNameUrl: "",
      navNameList: {
        公司概况: "/company",
        新闻中心: "/news",
        集团业务: "/business",
        招贤纳士: "/list?id=20&navName=招贤纳士&groupName=人才招聘",
        专题专栏: "/special",
        党建之窗: "/party",
        企业文化: "/list?id=255&navName=企业文化&groupName=文化理念",
        纪检监察: "/discipline"
      },
      navNameShow: true
    };
  },
  mounted() {
    var _this = this;
    // _this.appSrc = `https://m.ghatg.com/detail?id=${_this.$route.query.id}`
    if (_this.$route.query.navName) {
      _this.navNameShow = true;
      _this.name1 = _this.$route.query.navName;
      _this.navNameUrl = _this.navNameList[_this.$route.query.navName];
    } else {
      _this.navNameShow = false;
    }
    _this.name2 = _this.$route.query.groupName;
    // console.log( _this.$route.query.noList)
    if (_this.$route.query.noList == 1) {
      _this.getData2();
    } else {
      _this.getData();
    }
    _this.getJtdtNews();
  },
  methods: {
    handleEnter() {
      this.isEwm = true;
    },
    handleLeave() {
      this.isEwm = false;
    },
    pageList(index) {
      console.log("index :>> ", index);
    },
    doPrint() {
      var bdhtml = window.document.body.innerHTML;

      var sprnstr = "<!--startprint-->";
      var eprnstr = "<!--endprint-->";
      var prnhtml = bdhtml.substr(bdhtml.indexOf(sprnstr) + 17);
      prnhtml = prnhtml.substring(0, prnhtml.indexOf(eprnstr));
      window.document.body.innerHTML = prnhtml;
      window.print();
    },
    windowClose() {
      window.opener = null;
      window.open("about:blank", "_top").close();
    },
    toTop() {
      document.removeEventListener("scroll", this.handleScroll);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    async getData() {
      var _this = this;
      var id = _this.$route.query.id;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsDetail",
        { id: id, url: window.location.href }
      );
      if (data.data.status === 0) {
        _this.cont = data.data;
        document.title = data.data.title;
      }
      var url = data.wxConfig.url;
      delete data.wxConfig.url;
      if (isComWeiXin()) {
        this.comWeixin(data, url, id);
      } else if (isWeiXin()) {
        this.weixin(data, url, id);
      }
    },
    comWeixin(data, url, id) {
      let qywx = window.jweixin;
      qywx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.qywxConfig.appId, // 必填，企业微信的corpID
        timestamp: data.qywxConfig.timestamp + "", // 必填，生成签名的时间戳
        nonceStr: data.qywxConfig.nonceStr, // 必填，生成签名的随机串
        signature: data.qywxConfig.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: data.qywxConfig.jsApiList, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        url: url
      });

      qywx.error(function(res) {
        console.log("------------------------");
        console.log(res);
        console.log("------------------------");
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });

      // eslint-disable-next-line no-undef
      qywx.ready(() => {
        // eslint-disable-next-line no-undef
        wx.onMenuShareAppMessage({
          title: data.data.title, // 分享标题
          desc: this.setText(data.data.content), // 分享描述
          link: `https://m.ghatg.com/detail?id=${id}`, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: data.data.img
            ? data.data.img
            : "https://api.zq.ghatg.com/images/1677749724070.png", // 分享图标
          // enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function() {
            // 用户确认分享后执行的回调函数
          },
          error: function(res) {
            if (res.errMsg.indexOf("no permission") > 0) {
              alert("未agentConfig");
            }
          },
          cancel: function() {
            // 用户取消分享后执行的回调函数
          }
        });

        // eslint-disable-next-line no-undef
        qywx.onMenuShareWechat({
          title: data.data.title, // 分享标题
          desc: this.setText(data.data.content), // 分享描述
          link: `https://m.ghatg.com/detail?id=${id}`, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: data.data.img
            ? data.data.img
            : "https://api.zq.ghatg.com/images/1677749724070.png", // 分享图标
          // enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function() {
            // 用户确认分享后执行的回调函数
          },
          error: function(res) {
            if (res.errMsg.indexOf("no permission") > 0) {
              alert("未agentConfig");
            }
          },
          cancel: function() {
            // 用户取消分享后执行的回调函数
          }
        });

        // eslint-disable-next-line no-undef
        qywx.onMenuShareTimeline({
          title: data.data.title, // 分享标题
          link: `https://m.ghatg.com/detail?id=${id}`, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: data.data.img
            ? data.data.img
            : "https://api.zq.ghatg.com/images/1677749724070.png", // 分享图标
          // enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function() {
            // 用户确认分享后执行的回调函数
          },
          error: function(res) {
            if (res.errMsg.indexOf("no permission") > 0) {
              alert("未agentConfig");
            }
          },
          cancel: function(res) {
            console.log(res);
            // 用户取消分享后执行的回调函数
          }
        });
      });
    },
    weixin(data, url, id) {
      var wx_nqy = wx;
      wx_nqy.config(data.wxConfig);
      console.log(data.wxConfig);
      console.log(url);
      console.log(wx_nqy.config);
      wx_nqy.ready(() => {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        //分享到朋友圈
        console.log("wx.ready");
        wx_nqy.updateTimelineShareData({
          title: data.data.title, // 分享时的标题
          desc: this.setText(data.data.content),
          link: `https://m.ghatg.com/detail?id=${id}`, // 分享时的链接
          imgUrl: data.data.img
            ? data.data.img
            : "https://api.zq.ghatg.com/images/1677749724070.png", // 分享时的图标
          success: function() {
            console.log("分享成功");
          },
          cancel: function() {
            console.log("取消分享");
          },
          fail: function(res) {
            console.log("fail" + JSON.stringify(res));
          },
          complete: function(res) {
            //接口调用完成时执行的回调函数，无论成功或失败都会执行。
            console.log("complete" + JSON.stringify(res));
          }
        });
        //分享给朋友
        wx_nqy.updateAppMessageShareData({
          title: data.data.title,
          desc: this.setText(data.data.content),
          link: `https://m.ghatg.com/detail?id=${id}`,
          imgUrl: data.data.img
            ? data.data.img
            : "https://api.zq.ghatg.com/images/1677749724070.png", //分享图标
          type: "",
          dataUrl: "",
          success: function() {
            console.log("分享成功");
          },
          cancel: function() {
            console.log("取消分享");
          },
          fail: function(res) {
            console.log("fail" + JSON.stringify(res));
          },
          complete: function(res) {
            //接口调用完成时执行的回调函数，无论成功或失败都会执行。
            console.log("complete" + JSON.stringify(res));
          }
        });
      });
    },
    async getData2() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        {
          page: 1,
          group_id: _this.$route.query.id
        }
      );
      var detail = await _this.$http.post(
        _this.apiUrl + "/news/getBackstageNewsDetail",
        { id: data.data.list[0].id }
      );
      if (detail.data.data.status === 0) {
        _this.cont = detail.data.data;
      }
      // _this.cont = detail.data.data;
    },
    async getJtdtNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 7 }
      );
      _this.newsJtdt = data.data.list.slice(0, 5);
    },
    // 提取纯文本
    setText(val) {
      if (val != null && val != "") {
        var re1 = new RegExp("<.+?>|&.+?;", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
        var msg = val.replace(re1, ""); //执行替换成空字符
        msg = msg.replace(/\s/g, ""); //去掉所有的空格（中文空格、英文空格都会被替换）
        msg = msg.replace(/[\r\n]/g, ""); //去掉所有的换行符
        // return msg.substr(0, 100); //获取文本文字内容的前100个字符
        return msg;
      } else return "";
    }
  }
};
</script>

<style scoped>
.detail_ewm {
  text-align: center;
  margin: 30px 0;
}
.detail_ewm p {
  color: #666;
  margin-top: 15px;
}
.detail_ewm_img {
  width: 140px;
  height: 140px;
  margin: 0 auto;
}
.ewmFloat {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 10px;
  top: 50%;
  margin-top: -25px;
  z-index: 11;
}
.ewmFloatWx {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 68%;
  background-color: #2276ba;
  background-image: url(../assets/img/3_a.png);
  border-radius: 50%;
  cursor: pointer;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  position: relative;
}
.ewmFloatWx:hover {
  background-color: #005498;
}
.ewmFloatWxBox {
  position: absolute;
  /* width: 160px; */
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  text-align: center;
  padding-right: 10px;
}
.ewmFloatWxBox p {
  color: #666;
  margin-top: 0px;
}
.ewmFloatWxWrap {
  background-color: #fff;
  padding: 6px 6px 10px;
  /* box-shadow: 0 0 10px 1px #e5e5e5; */
}
.ewmFloatWxWrap img {
  width: 100% !important;
  height: auto !important;
}

</style>
