import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Index from "../views/index.vue";
import List from "../views/list.vue";
import Detail from "../views/detail.vue";
import Search from "../views/search.vue";
import Company from "../views/company.vue";
import News from "../views/news.vue";
import Business from "../views/business.vue";
import Member from "../views/member.vue"; //领导成员
import Party from "../views/party.vue";
import Special from "../views/special.vue";
import Jyxc from "../views/jyxc.vue"; //建言献策
import MemberSub from "../views/memberSub.vue";
import PartyNews from "../views/partyNews.vue";
import Discipline from "../views/discipline.vue"; //纪检监察
import Files from "../views/files.vue"; //档案工作
import Petition from "../views/petition.vue"; //公航旅集团《信访工作条例》落实年
import SearchCompany from "../views/searchCompany.vue"; //所属企业查询
import PartyLearn from "../views/partyLearn.vue"; //所属企业查询
import Implementation from "../views/implementation.vue"; //学习宣传贯彻“贯彻”精神

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Index",
        component: Index
    },
    {
        path: "/list",
        name: "List",
        component: List
    },
    {
        path: "/detail",
        name: "Detail",
        component: Detail
    },
    {
        path: "/search",
        name: "Search",
        component: Search
    },
    {
        path: "/company",
        name: "Company",
        component: Company
    },
    {
        path: "/news",
        name: "News",
        component: News
    },
    {
        path: "/business",
        name: "Business",
        component: Business
    },
    {
        path: "/member",
        name: "Member",
        component: Member
    },
    {
        path: "/party",
        name: "Party",
        component: Party
    },
    {
        path: "/special",
        name: "Special",
        component: Special
    },
    {
        path: "/jyxc",
        name: "Jyxc",
        component: Jyxc
    },
    {
        path: "/memberSub",
        name: "MemberSub",
        component: MemberSub
    },
    {
        path: "/partyNews",
        name: "PartyNews",
        component: PartyNews
    },
    {
        path: "/discipline",
        name: "Discipline",
        component: Discipline
    },
    {
        path: "/files",
        name: "Files",
        component: Files
    },
    {
        path: "/petition",
        name: "Petition",
        component: Petition
    },
    {
        path: "/searchCompany",
        name: "SearchCompany",
        component: SearchCompany
    },
    {
        path: "/partyLearn",
        name: "PartyLearn",
        component: PartyLearn
    },
    {
        path: "/implementation",
        name: "implementation",
        component: Implementation
    },
    {
        path: "home",
        name: "Home",
        component: Home
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/About.vue")
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
