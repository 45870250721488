<template>
  <div class="search list">
    <div class="container_wrap">
      <Header />

      <div class="search_container">
        <div class="search_hd clearfloat">
          <div class="fl">您现在位置： <span class="search_hd_txt01">全站搜索</span></div>
          <div class="fl">搜索词： <span class="search_hd_txt02">{{keyword}}</span></div>
          <div class="fl">找到相关新闻 <span class="search_hd_txt01">{{keyTotal}}</span><em> 篇</em></div>
          <div class="fl">搜索时间：<span class="search_hd_txt01">{{keyTime}} 秒</span></div>
        </div>
        <div class="search_wrap">
          <ul class="search_list">
            <li v-for="(item, index) in list" :key="index">
              <div class="search_list_hd single-line">
                <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=全站搜索&groupName=资讯信息`" v-html="item.title"></a>
                <a v-if="item.is_link === 1" target="_blank" :href="item.link_url" v-html="item.title"></a>
              </div>
              <div class="pageDes multi-line1" style="color: #999;font-size: 13px;" v-html="item.content">
              </div>
              <!-- <div class="search_list_cont" v-html="snippet(item.content)">
              </div> -->
            </li>
          </ul>

          <div class="list_page">
            <el-pagination
              background
              layout="total, prev, pager, next, jumper"
              :total="total"
              :page-size="pageSize"
              :current-page="currentPage"
              @current-change="pageList">
            </el-pagination>
          </div>
        </div>

      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";

export default {
  name: 'search',
  components: {
    Header,
    Footer
  },
  data(){
    return{
      keyword: '',
      keyTotal: '',
      keyTime: '',
      list: [],
      total: 2000,
      pageSize: 10,
      currentPage: 1
    }
  },
  filters: {
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 100) {
        return value.slice(0,100) + '...'
      }
      return value
    }
  },
  mounted() {
    var _this = this;
    _this.keyword = _this.$route.query.title;
    _this.getData();
  },
  methods: {
    pageList(index){
      var _this = this;
      console.log('index :>> ', index);
      _this.currentPage = index;
      _this.getData();
    },
    snippet(value){
      return value.slice(0, 100) + "..."; 
    },
    async getData() {
      var _this = this;
      var startTime = new Date();
      var title = _this.$route.query.title;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        {
          page: _this.currentPage,
          title: title,
          company_id: 1,
        }
      );
      var endTime = new Date();
      _this.keyTime =(endTime.getTime()-startTime.getTime())/1000; 
      _this.total = data.data.total;
      _this.keyTotal = data.data.total;
      _this.list = data.data.list;
      // item.title.replace(/集团/g, `<span style="color: #b30f00">${keyword}</span>`)
      var resultsList = _this.list;
      resultsList.map((item, index) => {
        // console.log('item', item)
        if (_this.keyword && _this.keyword.length > 0) {
          // 匹配关键字正则
          let replaceReg = new RegExp(_this.keyword, 'g')
          // 高亮替换v-html值
          let replaceString =
            '<span style="color: #b30f00">' + _this.keyword + '</span>'
          resultsList[index].title = item.title.replace(
            replaceReg,
            replaceString
          )
          let des = _this.setText(item.content)
          resultsList[index].content = des.replace(
            replaceReg,
            replaceString
          )
        }
      })
      console.log(resultsList)
      _this.list = [];
      _this.list = resultsList;
    },
    // 提取纯文本
    setText(val) {
      if (val != null && val != "") {
        var re1 = new RegExp("<.+?>|&.+?;","g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
        var msg = val.replace(re1,""); //执行替换成空字符
        msg = msg.replace(/\s/g,""); //去掉所有的空格（中文空格、英文空格都会被替换）
        msg = msg.replace(/[\r\n]/g,""); //去掉所有的换行符
        // return msg.substr(0, 100); //获取文本文字内容的前100个字符
        return msg;
      } else return ""
    },
  }
}
</script>
<style>
.search_hd_txt01 {
    color: #b30f00;
    font-weight: 600;
}

.search_hd_txt02 {
    color: #b30f00;
}

.search_list_hd {
    font-size: 17px;
    line-height: 36px;
}

.search_list_hd a:hover {
    color: #b30f00;
    text-decoration: underline;
}

.search_list_cont {
    line-height: 22px;
    text-indent: 2em;
}

.search_list li {
    padding: 14px 0;
    border-bottom: 1px solid #eee;
}
</style>