<template>
  <div class="business">
    <div class="list">
      <div class="container_wrap">
        <Header />

        <div class="list_container">
          <el-row :gutter="10">
            <el-col :span="7">
              <ul class="side_muen marb_15">
                <li>
                  <a href="/list?id=14&navName=集团业务&groupName=公路">公路</a>
                </li>
                <li>
                  <a href="/list?id=15&navName=集团业务&groupName=航空">航空</a>
                </li>
                <li>
                  <a href="/list?id=16&navName=集团业务&groupName=旅游">旅游</a>
                </li>
                <li>
                  <a href="/list?id=17&navName=集团业务&groupName=金融">金融</a>
                </li>
                <li>
                  <!-- <a href="/list?id=18&navName=集团业务&groupName=保险">保险</a> -->
                  <a href="http://www.ypic.cn/" target="_blank">保险</a>
                </li>
                <li>
                  <a href="/list?id=19&navName=集团业务&groupName=融资">融资</a>
                </li>
                <li>
                  <a href="/list?id=376&navName=集团业务&groupName=新能源">新能源</a>
                </li>
              </ul>
              <div class="marb_15">
                <NewsCompany />
              </div>
              <div class="marb_15">
                <Rank />
              </div>
              
            </el-col>

            <el-col :span="10">
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=14&navName=集团业务&groupName=公路">公路</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=14&navName=集团业务&groupName=公路">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listGl"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=集团业务&groupName=公路`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_rul">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=15&navName=集团业务&groupName=航空">航空</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=15&navName=集团业务&groupName=航空">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listHk"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=集团业务&groupName=航空`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=16&navName=集团业务&groupName=旅游">旅游</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=16&navName=集团业务&groupName=旅游">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listLy"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=集团业务&groupName=旅游`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=17&navName=集团业务&groupName=金融">金融</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=17&navName=集团业务&groupName=金融">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listJr"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=集团业务&groupName=金融`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <!-- <span><a href="/list?id=18&navName=集团业务&groupName=保险">保险</a></span> -->
                  <span><a href="http://www.ypic.cn/" target="_blank">保险</a></span>
                  <div class="fr company_more">
                    <!-- <a href="/list?id=18&navName=集团业务&groupName=保险">更多{{'>>'}}</a> -->
                    <a href="http://www.ypic.cn/" target="_blank">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listBx"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=集团业务&groupName=保险`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=19&navName=集团业务&groupName=融资">融资</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=19&navName=集团业务&groupName=融资">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listRz"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=集团业务&groupName=融资`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=376&navName=集团业务&groupName=新能源">新能源</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=376&navName=集团业务&groupName=新能源">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listXny"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=集团业务&groupName=新能源`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>

            </el-col>

            <el-col :span="7">
              <div class="marb_15">
                <Notic />
              </div>
              <div class="marb_15">
                <NewsPic />
              </div>
            </el-col>
          </el-row>

        </div>

        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Rank from "@/components/rank.vue";
import Notic from "@/components/notic.vue";
import NewsPic from "@/components/newsPic.vue";
import NewsCompany from "@/components/newsCompany.vue";

export default {
  name: 'business',
  components: {
    Header,
    Footer,
    Rank,
    Notic,
    NewsPic,
    NewsCompany
  },
  data(){
    return {
      listGl: [],
      listHk: [],
      listLy: [],
      listJr: [],
      listBx: [],
      listRz: [],
      listXny: [],
    }
  },
  mounted(){
    var _this = this

    _this.getDataGl()
    _this.getDataHk()
    _this.getDataLy()
    _this.getDataJr()
    _this.getDataBx()
    _this.getDataRz()
    _this.getDataXny()
  },
  methods: {
    async getDataGl() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 14 }
      );
      _this.listGl = data.data.list.slice(0, 5);
    },
    async getDataHk() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 15 }
      );
      _this.listHk = data.data.list.slice(0, 5);
    },
    async getDataLy() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 16 }
      );
      _this.listLy = data.data.list.slice(0, 5);
    },
    async getDataJr() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 17 }
      );
      _this.listJr = data.data.list.slice(0, 5);
    },
    async getDataBx() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 18 }
      );
      _this.listBx = data.data.list.slice(0, 5);
    },
    async getDataRz() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 19 }
      );
      _this.listRz = data.data.list.slice(0, 5);
    },
    async getDataXny() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 376 }
      );
      _this.listXny = data.data.list.slice(0, 5);
    },

  }
}
</script>