<template>
  <div class="newsCompany">
    <div class="sec_wrap">
      <div class="sec_tit">
        <span><a href="/list?id=7&navName=新闻中心&groupName=集团动态">集团动态</a></span>
      </div>
      <ul class="list_l_jtdt">
        <li
          class="single-line"
          v-for="(item, index) in list"
          :key="index"
        >
          <a target="_blank" :href="`/detail?id=${item.id}&navName=新闻中心&groupName=集团动态`">{{ item.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newsCompany',
  data(){
    return {
      list: []
    }
  },
  mounted(){
    var _this = this

    _this.getJtdtNews();
  },
  methods: {
    async getJtdtNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {page: 1, group_id: 7});
      _this.list = data.data.list.slice(0,5);
    },

  }
}
</script>