<template>
  <div class="list">
    <div class="container_wrap">
      <Header />

      <div class="list_container">
        <el-row :gutter="15">
          <el-col :span="7">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span>集团动态</span>
              </div>
              <ul class="list_l_jtdt">
                <li
                  class="single-line"
                  v-for="(item, index) in newsJtdt"
                  :key="index"
                >
                  <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=${name1}&groupName=${name2}`">{{ item.title }}</a>
                  <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                </li>
              </ul>
            </div>

            <div class="marb_15">
              <NewsPic />
            </div>

            <!-- <div class="sec_wrap" style="margin-top: 12px">
              <div class="sec_tit">
                <span>图片新闻</span>
              </div>
              <div class="list_l_xwdt">

                <div class="list_l_xwdt_list clearfloat" v-for="(item, index) in newsJtdt"
                  :key="index">
                  <div class="fl list_l_xwdt_fl">
                    <a class="list_l_xwdt_list_img" :href="`/detail?id=${item.id}&navName=${name1}&groupName=${name2}`"
                      ><img :src="item.img" alt=""
                    /></a>
                  </div>
                  <div class="list_l_xwdt_inner">
                    <p>
                      <a :href="`/detail?id=${item.id}&navName=${name1}&groupName=${name2}`"> {{ item.title }} </a>
                    </p>
                  </div>
                </div>


              </div>
            </div> -->

          </el-col>
          <el-col :span="17">
            <div class="list_hd">
              <span class="list_hd_posi"></span>
              <span class="list_hd_page"><a href="/">首页</a> > </span>
              <span class="list_hd_page" v-show="navNameShow"
                ><a :href="navNameUrl">{{ name1 }}</a> >
              </span>
              <span class="list_hd_page"
                ><a href="">{{ name2 }}</a> >
              </span>
              <span class="list_hd_page">列表</span>
            </div>

            <ul class="list_r_list">
              <li class="clearfloat" v-for="(item, index) in list" :key="index" :class="dayjs(item.create_time).format('YYYY-MM-DD')==dayjs().format('YYYY-MM-DD') ? 'news_new' : ''">
                <div class="list_r_list_dian fl"></div>
                <div class="list_r_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD HH:mm') }}</div>
                <div class="list_r_list_inner">
                  <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=${name1}&groupName=${name2}`">{{ item.title }}</a>
                  <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                </div>
              </li>
            </ul>

            <div class="list_page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
                :current-page="currentPage"
                @current-change="pageList"
              >
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import NewsPic from "@/components/newsPic.vue";

export default {
  name: "list",
  components: {
    Header,
    Footer,
    NewsPic
  },
  data() {
    return {
      name1: "",
      name2: "",
      total: 0,
      pageSize: 20,
      currentPage: 1,
      list: [],
      newsJtdt: [],
      navNameUrl: '',
      navNameList: {
        '公司概况': '/company',
        '新闻中心': '/news',
        '集团业务': '/business',
        '招贤纳士': '/list?id=20&navName=招贤纳士&groupName=人才招聘',
        '专题专栏': '/special',
        '党建之窗': '/party',
        '党史学习教育': '/partyNews',
        '企业文化': '/list?id=255&navName=企业文化&groupName=文化理念',
        '纪检监察': '/discipline',
        '档案工作': '/files',
        '公航旅集团《信访工作条例》落实年': '/petition',
        '党纪学习教育': '/partyLearn',
      },
      navNameShow: true
    };
  },
  mounted() {
    var _this = this;

    if(_this.$route.query.navName){
      _this.navNameShow = true
      _this.name1 = _this.$route.query.navName;
      _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.navNameShow = false
    }
    _this.name2 = _this.$route.query.groupName;
    _this.getData();
    _this.getJtdtNews();
  },
  methods: {
    pageList(index) {
      var _this = this;
      // console.log("index :>> ", index);
      _this.currentPage = index;
      _this.getData();
    },
    async getData() {
      var _this = this;
      var group_id = _this.$route.query.id;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        {
          page: _this.currentPage,
          group_id: group_id,
        }
      );
      _this.total = data.data.total;
      _this.list = data.data.list;
    },
    async getJtdtNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 7 }
      );
      _this.newsJtdt = data.data.list.slice(0, 5);
    },
  },
};
</script>

<style scoped>
</style>