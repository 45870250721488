<template>
  <div class="company">
    <div class="list">
      <div class="container_wrap">
        <Header />

        <div class="list_container">
          <el-row :gutter="10">
            <el-col :span="7">
              <ul class="side_muen marb_15">
                <li>
                  <a target="_blank" href="/detail?id=1&navName=公司概况&groupName=集团概况&noList=1">集团概况</a>
                </li>
                <li>
                  <a href="/member">领导成员</a>
                </li>
                <li>
                  <a href="/list?id=2&navName=公司概况&groupName=组织机构">组织机构</a>
                </li>
                <li>
                  <a href="/list?id=5&navName=公司概况&groupName=大事记">大事记</a>
                </li>
                <li>
                  <a href="/list?id=3&navName=公司概况&groupName=公司荣誉">公司荣誉</a>
                </li>
                <li>
                  <a target="_blank" href="/detail?id=6&navName=公司概况&groupName=联系我们&noList=1">联系我们</a>
                </li>
              </ul>
              <div class="marb_15">
                <Rank />
              </div>
              
            </el-col>

            <el-col :span="10">
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=2&navName=公司概况&groupName=组织机构">组织机构</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=2&navName=公司概况&groupName=组织机构">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listZzjg"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=公司概况&groupName=组织机构`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=2&navName=公司概况&groupName=大事记">大事记</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=2&navName=公司概况&groupName=大事记">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listDsj"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=公司概况&groupName=大事记`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
              <div class="sec_wrap company_wrap">
                <div class="sec_tit">
                  <span><a href="/list?id=2&navName=公司概况&groupName=公司荣誉">公司荣誉</a></span>
                  <div class="fr company_more">
                    <a href="/list?id=2&navName=公司概况&groupName=公司荣誉">更多{{'>>'}}</a>
                  </div>
                </div>
                <ul class="list_l_jtdt">
                  <li
                    class="single-line"
                    v-for="(item, index) in listGsry"
                    :key="index"
                  >
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`/detail?id=${item.id}&navName=公司概况&groupName=公司荣誉`">{{ item.title }}</a>
                    <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{ item.title }}</a>
                  </li>
                </ul>
              </div>
            </el-col>

            <el-col :span="7">
              <div class="marb_15">
                <Notic />
              </div>
              <div class="marb_15">
                <NewsPic />
              </div>
            </el-col>
          </el-row>

        </div>

        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Rank from "@/components/rank.vue";
import Notic from "@/components/notic.vue";
import NewsPic from "@/components/newsPic.vue";

export default {
  name: 'company',
  components: {
    Header,
    Footer,
    Rank,
    Notic,
    NewsPic
  },
  data(){
    return {
      listZzjg: [],
      listDsj: [],
      listGsry: [],
    }
  },
  mounted(){
    var _this = this

    _this.getDataZzjg()
    _this.getDataDsj()
    _this.getDataGsry()
  },
  methods: {
    async getDataZzjg() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 2 }
      );
      _this.listZzjg = data.data.list.slice(0, 5);
    },
    async getDataDsj() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 5 }
      );
      _this.listDsj = data.data.list.slice(0, 5);
    },
    async getDataGsry() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 3 }
      );
      _this.listGsry = data.data.list.slice(0, 5);
    },

  }
}
</script>